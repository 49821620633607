.guarantees-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px;
    justify-content: center;
    /* Center items vertically */
    align-items: stretch;
}

@media (max-width: 768px) {
    .guarantees-container {
        gap: 16px;
    }
}

.guarantee-icon {
    width: 90px;
}

.guarantee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    flex: 1 1 calc(30% - 16px);
    /* Optional, to limit card size */
    background-color: #fff;

    /* Enhanced shadow */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.06);
}

.guarantee-card .icon {
    font-size: 36px;
    margin-bottom: 8px;
    color: #007bff;
    /* Icon color */
}

.caption {
    font-size: 16px;
    color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
    .guarantee-card {
        flex: 1 1 100%;
    }
}