.product-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  background-color: #FFFFFF;
}

.gallery-thumbnail {
  width: 80px;
  height: 80px;
}

.media-gallery {
  display: flex;
  flex-direction: column;
  width: 35%;
  position: relative;
  /* Ensures absolute children are positioned relative to this container */
}


.hp-logo {
  width: 100px;
}

.style-image {
  object-fit: cover;
}

.banner {
  position: sticky;
  top: 0;
  z-index: 18;
  width: 100%;
  display: flex;
  color: #333;
  background-color: #fdfdf2;
  transition: height 0.3s ease, box-shadow 0.3s ease;
  height: 15vh;
  box-shadow: none; /* No shadow by default */
  /* Set banner height to 15% of the viewport height */
}

.banner.scrolled {
  height: 12vh; /* Shrink height on scroll */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for visual distinction */
}

.banner .logo {
  font-size: 24px;
  font-weight: bold;
}


.banner .nav-links {
  display: flex;
}

.banner .nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.nav-links:hover {
  /* Underline on hover */
  text-decoration: underline;
}

.product-image {
  flex: 1;
  max-width: 400px;
  margin: 10px;
}

.product-image img {
  width: 100%;
  border-radius: 8px;
}

.product-info {
  flex: 1;
  max-width: 500px;
  margin: 10px;
}

@media (min-width: 768px) {
  .product-info {
    margin-top: 0px;
  }
}

.product-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.current-price {
  color: #FF9AA2;
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-left: 10px;
}

.discount {
  background-color: #FF9AA2;
  color: #333;
  padding: 2px 5px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
}

.size-selection,
.copies-selection {
  margin-bottom: 20px;
}

.size-options button,
.copies-options button {
  background-color: #FFFFFF;
  border: 2px solid #000000;
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.size-options button.selected,
.copies-options button.selected {
  background-color: rgba(255, 154, 162, 0.5);
  color: #333;
}

.size-options button:hover,
.copies-options button:hover {
  background-color: rgba(255, 154, 162, 0.8);
}

.add-to-cart {
  background-color: rgba(255, 154, 162, 0.5);
  border: none;
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: rgba(255, 154, 162, 0.65);
}

.upload-section {
  position: relative;
}

.upload-button {
  display: inline-block;
  background-color: rgba(255, 154, 162, 0.5);
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: rgba(255, 154, 162, 0.65);
}


.bg-container {
  /* Background with repeating camera icon and parallax effect */
  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('images/camera-bg.png');
  /* Replace with your camera icon URL */
  background-size: 530px 530px;
  /* Adjust icon size */
  background-repeat: repeat;
  background-position: center;
  background-attachment: fixed;
}

.main-display {
  max-height: 80vh;
}

@media (max-width: 768px) {

  .bg-container {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('images/camera-bg.png');
    background-size: 350px 350px;
  }

  .banner .right .nav-links {
    display: none;
  }

  .media-gallery {
    width: 95%; /* Full width on smaller screens */
    max-width: none;  /* Remove max-width restriction */
    height: auto;   /* Crucial: Let the gallery height be determined by content */
  }
  .main-display {
    max-height: 50vh;
  }
  .thumbnail-row {
    height:auto;
  }
  .gallery-thumbnail {
    width: 80px;
    height: 80px;
  }
  
  .media-gallery .before-after-slider__first-photo-container {
    width: 100%;
    height: 100%;
  }

  .media-gallery .before-after-slider__first-photo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .media-gallery .before-after-slider__second-photo-container {
    width: 100%;
    height: 100%;
  }

  .media-gallery .before-after-slider__second-photo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-page {
    display: flex;
    flex-direction: column;
  }
}

.cart-drawer {
  position: fixed;
  top: 0;
  right: -35%;
  /* Start hidden */
  width: 35%;
  height: 100%;
  background-color: #fff;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.cart-drawer.open {
  right: 0;
  /* Slide into view */
}

.cart-content {
  padding: 20px;
}

.cart-drawer h3 {
  margin-bottom: 10px;
}

.custom-bg {
  background-color: #FFBEC3 !important;
  color: #000000;
  border: none;
}

.textbox-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  border-radius: 8px;
  border: none;
}

.textbox-title {
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
  margin-bottom: 8px;
}

.textbox-input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: vertical;
  background-color: #ffffff;
  color: #000000;
}

input:focus {
  outline: none !important;
  border-color: #FF9AA2;
  box-shadow: 0 0 5px #FF9AA2;
}

textarea:focus {
  outline: none !important;
  border-color: #FF9AA2;
  box-shadow: 0 0 5px #FF9AA2;
}

.modal-box {
  background-color: #ffffff;
  /* Solid white background for modal content */
  color: #333333;
  /* Darker text color for readability */
  border-radius: 8px;
  /* Optional: rounded corners */
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for better contrast */
}

