/* ConfirmationPage.css */

cp-body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.cp-confirmation-page {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
  width: 100%;
}

.cp-logo-container {
  margin-bottom: 20px;
}

.cp-logo {
  max-width: 150px;
  height: auto;
}

.cp-confirmation-content h2 {
  color: #333;
  margin-bottom: 15px;
}

.cp-confirmation-content p {
  color: #555;
  margin: 10px 0;
}

.cp-home-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cp-home-button:hover {
  background-color: #0056b3;
}
