.review-card {
  background: #fdfbfb;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 0.5px solid;
  display: flex;
  flex-direction: column;
}

.review-text {
  font-size: 16px;
  color: #555;
}

.review-author {
  color: #333;
  margin-top: 10px;
}

.swiper-pointer-disabled {
  pointer-events: none;
  /* Disable pointer events for the Swiper */
}

.review-media img {
  transition: opacity 0.7s ease, transform 0.5s ease;
}

.review-media {
  height: 400px;
}
@media (max-width: 768px) {
  .review-media {
    height: 300px;
  }
}