.media-container video {
  translate: 0 -20%;
}
.video-thumbnail {
  translate: 0 -20%;
}

.blur {
  filter: blur(10px); /* Adjust the blur intensity */
}

.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.media-container img,
.media-container video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .media-gallery {
    margin: 0 auto;
  }
}