.fading-banner {
  color: black;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .fading-banner {
    font-size: 12px;
    margin-bottom: 8px;
  }
}
.fading-text {
  transition: opacity 1s ease-in-out; /* Smooth fade effect */
  opacity: 1; /* Default state: visible */
}

.fading-text.fade-out {
  opacity: 0; /* Fade out state: invisible */
}

.fading-text.fade-in {
  opacity: 1; /* Fade in state: visible */
}
