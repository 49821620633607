
/* Cart.css */
.cart-drawer {
  position: fixed;
  top: 0;
  right: -35%; /* Start hidden */
  width: 35%;
  height: 100%;
  background-color: #fff;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.menu-drawer {
  display: none;
}

@media (max-width: 768px) {
  .menu-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; /* Optional if you want to add a label */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .menu-button:hover {
    background-color: #374151; /* Darker gray for hover effect */
    transform: scale(1.05); /* Slight scale-up effect */
  }
  
  .menu-button:active {
    transform: scale(0.95); /* Slight scale-down effect on click */
  }
  
  .menu-drawer {
    position: relative; /* Adjust based on layout */
    display:flex;
  }

  .cart-drawer {
    width: 100%;
    right: -100%; /* Start hidden on small screens */
  }

  .cart-drawer.open {
    right: 0; /* Slide into view as a full-screen modal */
  }
}

.cart-content {
  padding: 20px;
  position: relative;
}

.close-cart-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.cart-drawer h3 {
  margin-bottom: 10px;
}

.c-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.c-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
}


.c-cart-item {
  position: relative;
  /* Allow absolute positioning for the X button */
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f2f2f2;
  margin-bottom: 15px;
}

.c-cart-item-name {
  font-size: 1.5em;
  /* Largest font */
  font-weight: bold;
  color: #333;
}

.c-cart-item-type {
  font-size: 1em;
  /* Medium font */
  color: #666;
}

.c-cart-item-price {
  /* Slightly larger for price */
  font-weight: bold;
  align-self: flex-end;
  /* Align price to the right */
}

/* X button styling */
.c-remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  color: #e74c3c;
  cursor: pointer;
  transition: color 0.3s ease;
}

.c-remove-button:hover {
  color: #c0392b;
}

.c-confirm-button, .c-cancel-button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.c-confirm-button {
  background-color: #007bff;
  color: white;
}

.c-cancel-button {
  background-color: #ddd;
}